@tailwind base;
@tailwind components;
@tailwind utilities;

.prevent-body-scroll {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

input::-webkit-date-and-time-value {
  text-align: left;
}